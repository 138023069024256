import { default as apiNtByTO2fKBMeta } from "/opt/buildhome/repo/addicted.nuxt/pages/api.vue?macro=true";
import { default as index5zwczTyKxvMeta } from "/opt/buildhome/repo/addicted.nuxt/pages/index.vue?macro=true";
import { default as privacyvvYA3kNt6QMeta } from "/opt/buildhome/repo/addicted.nuxt/pages/privacy.vue?macro=true";
import { default as _91showName_937Ht9dTbClCMeta } from "/opt/buildhome/repo/addicted.nuxt/pages/shows/[showId]/[showName].vue?macro=true";
export default [
  {
    name: "Api",
    path: "/api",
    meta: apiNtByTO2fKBMeta || {},
    component: () => import("/opt/buildhome/repo/addicted.nuxt/pages/api.vue").then(m => m.default || m)
  },
  {
    name: "Home",
    path: "/",
    meta: index5zwczTyKxvMeta || {},
    component: () => import("/opt/buildhome/repo/addicted.nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "Privacy Policy",
    path: "/privacy",
    meta: privacyvvYA3kNt6QMeta || {},
    component: () => import("/opt/buildhome/repo/addicted.nuxt/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "show-details",
    path: "/shows/:showId()/:showName()",
    meta: _91showName_937Ht9dTbClCMeta || {},
    component: () => import("/opt/buildhome/repo/addicted.nuxt/pages/shows/[showId]/[showName].vue").then(m => m.default || m)
  }
]
import revive_payload_client_7J22GiD14h from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_bufferutil@4.0.8_typescript@5.5.4_utf-8-validate@6.0.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZjryXSJL0w from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_bufferutil@4.0.8_typescript@5.5.4_utf-8-validate@6.0.4_vite@5.4.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_npdaiPetmD from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_bufferutil@4.0.8_typescript@5.5.4_utf-8-validate@6.0.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_3IOTyU4tFv from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_bufferutil@4.0.8_typescript@5.5.4_utf-8-validate@6.0.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_EjasM7kqbu from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_bufferutil@4.0.8_typescript@5.5.4_utf-8-validate@6.0.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RDOejUTNvA from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_bufferutil@4.0.8_typescript@5.5.4_utf-8-validate@6.0.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_7Az2ue59gR from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_bufferutil@4.0.8_typescript@5.5.4_utf-8-validate@6.0.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_SSaN7Wigyg from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/addicted.nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_DNmx9v3Ofw from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_bufferutil@4.0.8_typescript@5.5.4_utf-8-validate@6.0.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_fUEvfpaGmZ from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@nuxtjs+device@3.1.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_CRo97Gtvg9 from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_pinia@2.2.2/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import cloudflare_client_l7hHizcRfX from "/opt/buildhome/repo/addicted.nuxt/plugins/cloudflare.client.ts";
import matomo_client_Ed2EqINkhd from "/opt/buildhome/repo/addicted.nuxt/plugins/matomo.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/addicted.nuxt/plugins/sentry.client.ts";
import vuetify_7h9QAQEssH from "/opt/buildhome/repo/addicted.nuxt/plugins/vuetify.ts";
export default [
  revive_payload_client_7J22GiD14h,
  unhead_ZjryXSJL0w,
  router_npdaiPetmD,
  payload_client_3IOTyU4tFv,
  navigation_repaint_client_EjasM7kqbu,
  check_outdated_build_client_RDOejUTNvA,
  chunk_reload_client_7Az2ue59gR,
  plugin_vue3_SSaN7Wigyg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DNmx9v3Ofw,
  plugin_fUEvfpaGmZ,
  plugin_CRo97Gtvg9,
  cloudflare_client_l7hHizcRfX,
  matomo_client_Ed2EqINkhd,
  sentry_client_shVUlIjFLk,
  vuetify_7h9QAQEssH
]